import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #1e1e1e;
  border: 2px solid #808080;
  padding: 1rem;
  font-weight: 800;
  color: #fff;

  h1 {
    border-bottom: none;
  }
`;

export const Tile = props => <Container>{props.children}</Container>;
