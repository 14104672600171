import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Subheader from '../components/Subheader';
import { getTheme } from '../utility/theme';
import { Tile } from '../components/Tile';
import { PRIMARY, MAX_TABLET_WIDTH } from '../constants';
import Notes from './notes';

const Row = styled.div`
  margin-bottom: 1rem;
`;
const Container = styled.div`
  .link-row2 {
    height: 100%;
    display: flex;
    align-items: center;
    height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    margin-left: -0.5rem;
    width: calc(100% + 1rem);
  }
  .tile-link {
    width: calc(50% - 1rem);
    margin-right: 1rem;
    display: inline-flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-bottom: 1rem;
    height: 8rem;

    &:hover {
      text-decoration-color: white;

      div {
        background: #222;
        border: 1px solid ${PRIMARY};
      }
    }

    @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
      width: 100%;
      height: 4rem;
    }
  }

  link a {
    color: red;
  }
`;

interface Props {}
const IndexPage: React.FunctionComponent<Props> = () => {
  const theme = getTheme();
  return (
    <Container theme={theme}>
      <Layout>
        <SEO title="Home" />
        <Subheader crumbs={[]} />

        <Link to="/systems" className="tile-link">
          <Tile>
            <h1>Notes</h1>
          </Tile>
        </Link>

        <Link to="/drugs" className="tile-link">
          <Tile>
            <h1>Drugs</h1>
          </Tile>
        </Link>

        <Link to="/about-us" className="tile-link">
          <Tile>
            <h1>About us</h1>
          </Tile>
        </Link>

        <Link to="/contact" className="tile-link">
          <Tile>
            <h1>Contact us</h1>
          </Tile>
        </Link>
      </Layout>
    </Container>
  );
};

export default IndexPage;
